const initState = {
    isUserLoggedIn: false,
    userId: 0,
    localTZ: "",
    isLoading: false,
    userData: {
        Email: "",
        Id: "",
        Name: ""
    },
}

export const reducer = (globalState = initState, action) => {
    if(action.type==="SET_ISUSERLOGGEDIN"){
        return {
            ...globalState,
            isUserLoggedIn:action.payload
        }
    }
    if(action.type==="SET_USERID"){
        return {
            ...globalState,
            userId:action.payload
        }
    }
    if(action.type==="SET_USERDATA"){
        return {
            ...globalState,
            userData:action.payload
        }
    }
    if(action.type==="SET_LOCALTZ"){
        return {
            ...globalState,
            localTZ:action.payload
        }
    }
    if(action.type==="SET_ISLOADING"){
        return {
            ...globalState,
            isLoading:action.payload
        }
    }
    
    return globalState
}

export default reducer