import React, { useEffect } from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import './index.css';
import { useSelector, useDispatch } from "react-redux";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const LandingPageComponent = React.lazy(() => import('./containers/LandingPage'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const App = () => {

  const dispatch = useDispatch();

  let isUserLoggedIn = useSelector((globalState)=>{
      return globalState.isUserLoggedIn
  })

  useEffect(() => {
    var hours = 24; // Reset when storage is more than 24hours
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
        localStorage.setItem('setupTime', now)
    } else {
        if(now-setupTime > hours*60*60*1000) {
            localStorage.clear()
            localStorage.setItem('setupTime', now);
        }
    }

    let timeZone = localStorage.getItem('localTimeZone');

    if(timeZone == null){
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      localStorage.setItem('localTimeZone', timeZone)
    }

    dispatch({type:"SET_LOCALTZ",payload:timeZone})


    var isUserLoggedIn = localStorage.getItem('isUserLoggedIn');
    if (isUserLoggedIn != null) {
      dispatch({type:"SET_ISUSERLOGGEDIN",payload:isUserLoggedIn})
    }

    let userData = JSON.parse(localStorage.getItem('UserData'))
    if(userData != null){
      dispatch({type:"SET_USERID",payload:userData.Id})
      dispatch({type:"SET_USERDATA",payload:userData})
    }

  })

  return (
      <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              {
                isUserLoggedIn
                ?
                  <Route path="/" name="Home" render={props => <LandingPageComponent {...props}/>} />
                : 
                  <Route path="/" name="Login Page" render={props => <Login {...props}/>} />
              }
              
            </Switch>
          </React.Suspense>
      </Router>
    );
}

export default App;
